var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-dialog',{staticClass:"edit-address-confirm-dialog",attrs:{"visible":_vm.visible,"show-close":true,"append-to-body":true},on:{"update:visible":_vm.updateVisible,"opened":_vm.exposeConfirmDialog}},[_c('div',{staticClass:"edit-address-confirm-dialog__tips"},[_vm._v("\n    "+_vm._s(_vm.batchModifyAddress.modify_type == 1
        ? _vm.language.SHEIN_KEY_PWA_26586
        : _vm.language.SHEIN_KEY_PWA_26589)+"\n  ")]),_vm._v(" "),_c('template',{slot:"footer"},[_c('div',{staticClass:"edit-address-confirm-dialog__footer"},[(_vm.batchModifyAddress.modify_type == 1)?[_c('s-button',{directives:[{name:"tap",rawName:"v-tap",value:({
            id: '1-20-1-79',
            data: {
              order_no: _vm.billno,
              modify_type: _vm.batchModifyAddress.modify_type,
              cross_type: 0
            }
          }),expression:"{\n            id: '1-20-1-79',\n            data: {\n              order_no: billno,\n              modify_type: batchModifyAddress.modify_type,\n              cross_type: 0\n            }\n          }"}],staticClass:"edit-address-confirm-btn",attrs:{"type":['primary', 'H72PX']},on:{"click":function($event){return _vm.onConfirm(0)}}},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_26587)+"\n        ")]),_vm._v(" "),(!_vm.isUsCombined)?_c('div',{staticClass:"view-detail"},[_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
              id: '1-20-1-80',
              data: {
                order_no: _vm.billno
              }
            }),expression:"{\n              id: '1-20-1-80',\n              data: {\n                order_no: billno\n              }\n            }"}],staticClass:"view-detail__btn",on:{"click":_vm.viewDetails}},[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_26588)+"\n          ")])]):_vm._e()]:(_vm.batchModifyAddress.modify_type == 2)?[_c('s-button',{directives:[{name:"tap",rawName:"v-tap",value:({
            id: '1-20-1-79',
            data: {
              order_no: _vm.billno,
              modify_type: _vm.batchModifyAddress.modify_type,
              cross_type: 0
            }
          }),expression:"{\n            id: '1-20-1-79',\n            data: {\n              order_no: billno,\n              modify_type: batchModifyAddress.modify_type,\n              cross_type: 0\n            }\n          }"}],staticClass:"edit-address-confirm-btn",attrs:{"type":['primary', 'H72PX']},on:{"click":function($event){return _vm.onConfirm(0)}}},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_26587)+"\n        ")]),_vm._v(" "),(!_vm.isUsCombined)?_c('s-button',{directives:[{name:"tap",rawName:"v-tap",value:({
            id: '1-20-1-79',
            data: {
              order_no: _vm.billno,
              modify_type: _vm.batchModifyAddress.modify_type,
              cross_type: 1
            }
          }),expression:"{\n            id: '1-20-1-79',\n            data: {\n              order_no: billno,\n              modify_type: batchModifyAddress.modify_type,\n              cross_type: 1\n            }\n          }"}],staticClass:"edit-address-confirm-btn",attrs:{"type":['H72PX']},on:{"click":function($event){return _vm.onConfirm(1)}}},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_26590)+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.isUsCombined)?_c('div',{staticClass:"view-detail"},[_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
              id: '1-20-1-80',
              data: {
                order_no: _vm.billno
              }
            }),expression:"{\n              id: '1-20-1-80',\n              data: {\n                order_no: billno\n              }\n            }"}],staticClass:"view-detail__btn",on:{"click":_vm.viewDetails}},[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_26596)+"\n          ")])]):_vm._e()]:_vm._e()],2)]),_vm._v(" "),_c('order-merge-drawer',{attrs:{"visible":_vm.showOrderMergeDrawer,"language":_vm.language,"billno":_vm.billno,"batch-modify-address":_vm.batchModifyAddress},on:{"update:visible":function($event){_vm.showOrderMergeDrawer=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }